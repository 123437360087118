import { useTranslation } from '@hooks';

import { H3, Text3, Subtitle3 } from '@components/styles/Typography';
import {
    AgreementModalWrapper,
    MarginWrapper,
    StaticContentArea,
    StaticContentRow,
} from 'components/styles/StaticPagesStyles';

const UserAgreement = () => {
    const { locale } = useTranslation();

    return (
        <AgreementModalWrapper>
            {locale === 'tr' ? (
                <StaticContentArea>
                    <Subtitle3>
                        <H3>
                            WEB SİTESİ ÜYELİĞİ KAPSAMINDA KİŞİSEL VERİLERİNİN
                            İŞLENMESİNE İLİŞKİN AYDINLATMA METNİ
                        </H3>
                    </Subtitle3>
                    <StaticContentArea>
                        <Text3>Değerli Üye/Üye Adayımız;</Text3>
                        <Text3>
                            Kişisel verileriniz 6698 sayılı Kişisel Verilerin
                            Korunması Kanunu uyarınca veri sorumlusu olarak
                            Keikei Tekstil Ve Giyim Sanayi Ticaret Anonim
                            Şirketi (<bold>“[KEİKEİ]”</bold> veya{' '}
                            <bold>“Şirket”</bold>) tarafından aşağıda açıklanan
                            şekilde işlenebilecektir.
                        </Text3>
                        <MarginWrapper>
                            <Subtitle3>
                                Kişisel Verileri Toplama Yöntemleri ve Hukuki
                                Sebepleri:
                            </Subtitle3>
                            <Text3>
                                Şirketimiz, kişisel verileri, Üye olma
                                aşamasında vermiş olduğunuz bilgilerden ve/veya
                                sonrasında web sitesi içerisinde yer alan üyelik
                                bilgilerim kısmı üzerinden sonradan
                                ekleyip/değiştireceğiniz bilgilerden elde
                                etmektedir. Kişisel verileriniz; üyelik
                                sözleşmesinin kurulması ve ifası için veri
                                işlemenin gerekli olması (sözleşme tarafı olarak
                                ad-soyad gibi bilgileriniz,), kanunlarda açıkça
                                öngörülme, hukuki yükümlülüğün yerine
                                getirilmesi, veri sorumlusunun meşru menfaati
                                ve/veya Bir hakkın tesisi, kullanılması veya
                                korunması (ID-PASWORD bilgileri), sonraki
                                kurulabilecek sözleşmelere hazırlık (adres ve
                                fatura bilgileriniz) açık rıza (ticari
                                elektronik ileti gönderilebilmesi için işlenen
                                iletişim verileriniz ile cinsiyet, doğum tarihi,
                                lokasyon verileriniz) hukuki sebeplerine dayalı
                                olarak işlemektedir.
                            </Text3>
                        </MarginWrapper>
                        <MarginWrapper>
                            <Subtitle3>
                                Farklı Aşamalarda İşlenebilecek Veri
                                Kategorileri ve Veri Türleri
                            </Subtitle3>
                            <StaticContentRow>
                                <Subtitle3>Kimlik:</Subtitle3>
                                <Text3>
                                    Ad-Soyad, Doğum Tarihi(*), Cinsiyet(*)
                                </Text3>
                            </StaticContentRow>
                            <StaticContentRow>
                                <Subtitle3>İletişim:</Subtitle3>
                                <Text3>
                                    Telefon Numarası, Şehir, İlçe/Semt, Açık
                                    Adres, E-posta adresi, lokasyon(*)
                                </Text3>
                            </StaticContentRow>
                            <StaticContentRow>
                                <Subtitle3>Kullanıcı Bilgileri:</Subtitle3>
                                <Text3>Şifre, Kullanıcı Adı</Text3>
                            </StaticContentRow>
                            <StaticContentRow>
                                <Subtitle3>Finans:</Subtitle3>
                                <Text3>
                                    Fatura Adresi ve V.K.No, Vergi Dairesi ile
                                    Diğer Fatura Bilgileri,{' '}
                                </Text3>
                            </StaticContentRow>
                            <Text3>
                                *İlgili kişisel verileriniz ancak sizlerin
                                üyelik bilgilerim kısmından sonradan bildirmeniz
                                ve açık rızanız kapsamında işlenmektedir.
                            </Text3>
                        </MarginWrapper>
                        <Text3>
                            Tabloda belirtilen kişisel veriler olağan
                            faaliyetler kapsamında işlenebilecek kişisel
                            verilerinizi belirtmekte olup istisnai işlemler
                            kapsamında yine bu Aydınlatma Metni bağlamında
                            kişisel verileriniz işlenebilir.
                        </Text3>
                        <MarginWrapper>
                            <Subtitle3>
                                Kişisel Verileri İşleme Amaçları
                            </Subtitle3>
                            <Text3>
                                Kişisel verileriniz, müşteri kayıtlarının
                                oluşturulması ve takibi, üyelik sözleşmesinin
                                kurulması ve ifa edilebilmesi, satış
                                sözleşmelerine hazırlık amacı ve kolaylık
                                sağlanması için, müşteriye web sitesinin
                                kullanımında kolaylık sağlanması, talep ve
                                şikayetlerin takibi, iş sürekliliği ve
                                faaliyetin devamlılığının sağlanması, pazarlama
                                ve analiz çalışmalarının yürütülmesi, müşteri
                                memnuniyetine yönelik aktivitelerin yürütülmesi,
                                Ticari İletişim ve Ticari Elektronik İletiler
                                Hakkında Yönetmelik kapsamında izin veren
                                müşterilerimize kampanya - indirim vb.
                                durumlarda müşterinin yararlanabilmesi için
                                haberdar edilmesi, bilgi güvenliği süreçlerinin
                                planlanması, denetim ve icrası, yetkili kişi,
                                kurum ve kuruluşlara bilgi verilmesi, bilgi
                                teknolojileri alt yapısının oluşturulması ve
                                yönetilmesi amaçlarıyla işlenmektedir.
                            </Text3>
                        </MarginWrapper>
                        <MarginWrapper>
                            <Subtitle3>Kişisel Verilerin Aktarılması</Subtitle3>
                            <Text3>
                                Kişisel verileriniz, “bilme gereği” ve “kullanma
                                gereği” ilkelerine uygun olarak ve gerekli veri
                                minimizasyonunu sağlanarak; yukarıda belirtilen
                                amaçların gerçekleştirilmesi doğrultusunda ve bu
                                amaçların yerine getirilmesi ile sınırlı olarak;
                                hissedarlarımızla, iş ortaklarımızla,
                                iştiraklerimiz ve bağlı şirketlerimiz ve
                                hissedarlarıyla, Şirketimizin yurtiçi ve
                                yurtdışındaki hizmet sağlayıcılarıyla,
                                pazarlama/reklam/analiz hizmeti sağlayıcıları,
                                veri tabanı ve sunucu hizmeti sağlayıcıları,
                                site kullanımlarını izleme hizmeti sağlayanlar,
                                e-posta sunucu hizmeti sağlayıcıları, e-fatura
                                ve e-arşiv fatura hizmeti sağlayıcıları,
                                elektronik ileti aracı hizmet sağlayıcıları,
                                kargo ve kurye firmaları, banka ve elektronik
                                ödeme kuruluşları, hukuki ve mali danışmanlık
                                hizmeti verenler bağımsız denetim hizmeti
                                sağlayıcıları, arşivleme hizmeti verenler,
                                bilişim altyapımızı sağlayan, işleten veya
                                hizmet sunan iş ortaklarımızla ve hizmet
                                sağlayıcılarımızla, özel iş ortaklığımızın
                                olduğu kanunen yetkili kamu kurumları ve özel
                                kişi veya kuruluşlar ile üçüncü kişilere,
                                <br />
                                Ayrıca;
                            </Text3>
                            <Text3>
                                Denetleme ve durum tespit faaliyetleri için
                                uzmanlar, hukuk büroları ve denetleme firmaları
                                ile düzenleyici ve sözleşmesel yükümlülüklerin
                                yerine getirilmesi için kamu kurumları, sigorta
                                şirketleri, sosyal yardım destek fonları, iş
                                danışmanlarıyla,
                            </Text3>
                            <Text3>
                                Yetkili idari ve denetleme kurullarıyla ve/veya
                                diğer yetkili denetleyici kurum ve kuruluşlarla,
                            </Text3>
                            <Text3>
                                Hukuki uyuşmazlıkların giderilmesi veya ilgili
                                mevzuatı gereği talep halinde idari makamlar,
                                adli makamlar veya ilgili kolluk kuvvetleriyle
                                işbu aydınlatma metninde belirtilen amaçlarla
                                sınırlı olarak aktarılabilecek, yurt içinde veya
                                yurt dışında işlenebilecektir.
                            </Text3>
                        </MarginWrapper>
                        <MarginWrapper>
                            <Subtitle3>İlgili Kişinin Hakları</Subtitle3>
                            <Text3>
                                Kişisel verisi işlenen kişi olarak, Kanun’un
                                ilgili kişinin haklarını düzenleyen 11. maddesi
                                kapsamındaki haklarınızı (kişisel veri işlemeyi
                                öğrenme, işlemeyle ilgili bilgi talep etme,
                                işlemenin amaca uygunluğunu öğrenme, aktarım
                                yapılan kişileri bilme, eksik veya yanlış
                                işlemelerin düzeltilmesini isteme, silme veya
                                yok edilmesini isteme, otomatik tüm işlemlerin
                                üçüncü kişilere bildirilmesini isteme, analize
                                itiraz etme, zararın giderilmesini talep etme)
                                Veri Sorumlusuna Başvuru Usul ve Esasları
                                Hakkında Tebliğe göre kullanmak için ile
                                kvkk@keikei.co.tr iletişime geçebilir veya
                                www.keikei.com adresinde yer alan “Başvuru
                                Formu”nu doldurup;
                            </Text3>
                            <Text3>
                                Dolapdere Cd. Küçükbayır Sk. 119/A Şişli /
                                İstanbul adresine bizzat getirebilir,
                                <br />
                                Belirtilen adrese, ıslak imzalı olarak noter
                                kanalıyla gönderebilir,
                                <br />
                                keikei@hs01.kep.tr adresine güvenli elektronik
                                ya da mobil imzalı olarak, kayıtlı elektronik
                                posta adresi veya sistemimizde kayıtlı
                                elektronik e-posta adresiniz aracılığıyla
                                iletebilirsiniz.
                            </Text3>
                            <Text3>
                                Başvuruda ad, soyad, Türkiye Cumhuriyeti
                                vatandaşları için T.C. kimlik numarası,
                                yabancılar için uyruğu, pasaport numarası veya
                                varsa kimlik numarası, tebligata esas yerleşim
                                yeri veya iş yeri adresi, varsa bildirime esas
                                elektronik posta adresi, telefon ve faks
                                numarası ve talep konusu yer almalıdır.
                            </Text3>
                            <Text3>
                                Başvurunuza yazılı olarak cevap verilecekse, on
                                sayfaya kadar ücret alınmaz. On sayfanın
                                üzerindeki her sayfa için 1 Türk Lirası işlem
                                ücreti alınabilir. Başvuruya cevabın CD, flash
                                bellek gibi bir kayıt ortamında verilmesi
                                halinde Şirketimiz, kayıt ortamının maliyetini
                                tarafınızdan talep edilebilecektir.
                            </Text3>
                        </MarginWrapper>
                    </StaticContentArea>
                </StaticContentArea>
            ) : (
                <StaticContentArea>
                    <Subtitle3>
                        <H3 size="14px">
                            WITHIN THE SCOPE OF WEBSITE MEMBERSHIP CLARIFICATION
                            TEXT ON THE PROCESSING OF PERSONAL DATA
                        </H3>
                    </Subtitle3>
                    <StaticContentArea>
                        <Text3>Dear member/candidate member;</Text3>
                        <Text3>
                            Your personal data may be processed as described
                            below by Keikei Textile And Clothing Industry Trade
                            Joint Stock Company ({' '}
                            <Subtitle3>“KEİKEİ” or “Company”</Subtitle3>)as the
                            data controller in accordance with the Law on
                            Protection of Personal Data No. 6698.
                        </Text3>
                        <Subtitle3>
                            Personal Data Collection Methods and Legal Reasons:
                        </Subtitle3>
                        <Text3>
                            Our company obtains personal data from the
                            information you provide during the membership
                            process and/or from the information you add/change
                            later on through the membership information section
                            of the website. Your personal data may be operated
                            on the basis of the necessity of data processing for
                            the establishment and performance of the membership
                            agreement (such as name-surname as a part of the
                            contract), clearly stipulated in the laws,
                            fulfillment of the legal obligation, legitimate
                            interest of the data controller and / or
                            establishment, use or protection of a
                            right(ID-password informations), preparation for the
                            next contracts(address and invoice informations),
                            express consent(your communication data processed in
                            order to send commercial electronic messages and
                            your gender, date of birth, location data).
                        </Text3>
                        <Subtitle3>
                            Data Categories and Data Types That Can Be Processed
                            at Different Stages:
                        </Subtitle3>
                        <StaticContentRow>
                            <Subtitle3>Identification:</Subtitle3>
                            <Text3>
                                Name-Surname, date of birth(*),Gender(*)
                            </Text3>
                        </StaticContentRow>
                        <StaticContentRow>
                            <Subtitle3>Communication:</Subtitle3>
                            <Text3>
                                Phone Number, City, Town, District, Open
                                Address, E-mail address, location(*)
                            </Text3>
                        </StaticContentRow>
                        <StaticContentRow>
                            <Subtitle3>User Information:</Subtitle3>
                            <Text3>Password, User Name </Text3>
                        </StaticContentRow>
                        <StaticContentRow>
                            <Subtitle3>Cap Address:</Subtitle3>
                            <Text3>keikei@hs01.kep.tr </Text3>
                        </StaticContentRow>
                        <StaticContentRow>
                            <Subtitle3>Finance:</Subtitle3>
                            <Text3>
                                Invoice address and tax identification number,
                                tax administration and other invoice
                                informations,
                            </Text3>
                        </StaticContentRow>
                        <Text3>
                            * Your relevant personal data is only processed
                            within the scope of your notification and your
                            express consent from the section of my membership
                            information.
                        </Text3>
                        <Text3>
                            The personal data specified in the table indicate
                            your personal data that can be processed within the
                            scope of ordinary activities, and within the scope
                            of exceptional transactions, your personal data may
                            be processed in the context of this Clarification
                            Text.
                        </Text3>
                        <Subtitle3>Personal Data Processing Purposes</Subtitle3>
                        <Text3>
                            Your personel data is processed for creation and
                            follow-up of customer records, establishment and
                            execution of the membership agreement, preparing
                            sales contract and convenience, providing
                            convenience to the customer in the use of the
                            website, follow-up of requests and complaints,
                            Ensuring business continuity and continuity of
                            activity, conducting marketing and analysis studies,
                            carrying out activities for customer happiness,
                            Within the scope of the Regulation on Commercial
                            Communication and Commercial Electronic Messages,
                            our customers who give permission, offer campaigns,
                            discounts, etc. informing the customer so that they
                            can benefit from, planning, auditing and execution
                            of information security processes, Providing
                            information to authorized persons, institutions and
                            organizations, Creation and management of
                            information technology infrastructure
                        </Text3>
                        <Subtitle3>Transfer of Personal Data</Subtitle3>
                        <Text3>
                            Your personal data may be processed in accordance
                            with the principles of “need to know” and “need to
                            use” and by providing the necessary data
                            minimization; with our shareholders, business
                            partners, affiliates and subsidiaries and
                            shareholders, With our company&#39;s domestic and
                            international service providers, e-invoice and
                            e-archive invoice service providers, electronic
                            message service providers, cargo and courier
                            companies, banks and electronic payment
                            institutions, legal and financial consultancy
                            services, independent audit service providers,
                            archiving services, our business partners and
                            service providers that provide, operate or provide
                            services to our IT infrastructure, legally
                            authorized public institutions and private persons
                            or organizations with which we have private business
                            partnerships, and third parties,
                            <br />
                            Besides;
                        </Text3>
                        <Text3>
                            Experts, law firms and audit firms for auditing and
                            due diligence activities and public institutions for
                            the fulfillment of regulatory and contractual
                            obligations, insurance companies, , social
                            assistance support funds, business advisors,
                        </Text3>
                        <Text3>
                            With the authorized administrative and supervisory
                            boards and/or other authorized supervisory
                            institutions and organizations,
                        </Text3>
                        <Text3>
                            In the country or abroad, which can be transferred
                            to the administrative authorities, judicial
                            authorities or relevant law enforcement authorities
                            for the resolution of legal disputes or in
                            accordance with the relevant legislation, limited to
                            the purposes specified in this clarification text.
                        </Text3>
                        <Subtitle3>Rights of concerned person</Subtitle3>
                        <Text3>
                            As the person whose personal data is processed, to
                            exercise Your rights under Article 11 of the Law,
                            which regulates the rights of the person concerned
                            in line with Communiqué on Application Procedures
                            and Principles to Data Controller you can contact
                            kvkk@keikei.co.tr or fill the “Application form” on
                            the www.keikei.com;
                        </Text3>

                        <Text3>
                            You may bring the form to Dolapdere Road Küçükbayır
                            St. 119/A Şişli/İstanbul by yourself, You may send
                            to the specified address, with wet signature,
                            through a notary public, You may send it to
                            keikei@hs01.kep.tr with secure electronic or mobile
                            signature, via your registered e-mail address or
                            your e-mail address registered in our system. In the
                            application there must be name, surname, T.C.
                            identification number for Turhish citizens,
                            nationality for foreigners, passport number or
                            identification number, place of residence or
                            business adress, e-mail address for notification,
                            phone and fax number and subject of application. If
                            your application will be answered in writing, up to
                            ten pages will not be charged. A transaction fee of
                            1 Turkish Lira may be charged for each page if it is
                            over ten pages. If the response to the application
                            is given in a recording medium such as CD or flash
                            memory, our Company may request the cost of the
                            recording medium from you.
                        </Text3>
                    </StaticContentArea>
                </StaticContentArea>
            )}
        </AgreementModalWrapper>
    );
};

export default UserAgreement;
