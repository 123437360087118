import styled from 'styled-components';

export const StaticPageWrapper = styled.div`
    max-width: 1080px;
    margin: 0 auto;
    margin-top: ${({ marginTop }) => marginTop || '150px'};

    @media (max-width: 1024px) {
        margin-top: ${({ marginTopMobile }) => marginTopMobile || '74px'};
        padding-top: ${({ type }) => (type === 'about' ? '100px' : 'auto')};
        background-color: ${({ theme }) => theme.colors.tertiaryVariant};
        min-height: 100vh;
        width: 100vw;
    }
`;
export const StaticPageContainer = styled.div`
height: 100%;
    p {
        margin: 10px 0;
    }
    @media (max-width: 1024px) {
        background-color: ${({ theme }) => theme.colors.secondary};
        margin: 20px;
        padding: 20px;
        border-radius: 3px;
    }
`;

export const StaticPageImg = styled.picture`
    width: 100%;
    border-radius: 3px;

    img {
        width: 100%;
        margin-top: 10px;
    }
`;

export const StaticContentArea = styled.div`
    width: 100%;
    height: 470px;
    padding: 10px;
    overflow-y: auto;

    @media (max-width: 768px) {
        height: 100%;
    }
`;

export const StaticContentRow = styled.div`
    display: flex;
    align-items: flex-start;
    margin: 5px 0;
`;
export const PageMenuContainer = styled.div`
    width: 350px;
    margin-top: 50px;

    @media (max-width: 1024px) {
        margin-top: 0;
    }
`;
export const MarginWrapper = styled.div`
    margin: 10px 0;
`;
export const AgreementModalWrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: scroll;
`;
